/* =========================================
                Mobile Menu
============================================ */

.navbar-header {
  position: relative;
}

#mobile-nav-open-btn {
  font-size: 30px;
  color: #2eabd0;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  right: 0;
  top: 6px;
  /* hide mobile nav open btn */
  display: none;
}

#mobile-nav {
  /* by default, mobile nav will be hidden with height 0% */
  height: 0%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  overflow-y: hidden;
}

#mobile-nav-close-btn {
  font-family: "Raleway", sans-serif;
  font-size: 70px;
  color: #212226;
  font-weight: 400;
  cursor: pointer;
  position: absolute;
  top: -9px;
  right: 13px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#mobile-nav-content {
  text-align: center;
  width: 100%;
  margin-top: 30px;
  position: relative;
  top: 10%;
}

#mobile-nav ul li {
  margin-bottom: 20px;
}

#mobile-nav a {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: #212226;
  font-weight: 400;
  text-transform: uppercase;
  display: inline;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#mobile-nav a:hover,
#mobile-nav a:focus,
#mobile-nav-close-btn:hover,
#mobile-nav-close-btn:focus {
  color: #2eabd0;
  background: none;
}

/* Mobile nav scroll spy active state */

.white-nav-top #mobile-nav ul.nav > li.active > a {
  color: #2eabd0;
  font-weight: 500;
}

@media (min-width: 1200px) {
  .display-flex {
    display: flex !important;
  }
}

/*==================================================
			Bootstrap 3 Media Queries        
==================================================*/

/* Large Devices (Desktops & Laptops) */

@media (min-width: 1200px) and (max-width: 1299px) {
  /* Navigation */
  .site-nav-wrapper {
    padding: 0 20px;
  }
  .display-flex {
    display: flex !important;
  }
  .home-heading-img {
    height: 475px;
  }
}

/* Medium Devices (Landscape Tablets & Medium Desktops) */

@media (min-width: 992px) and (max-width: 1199px) {
  /* Navigation */
  .site-nav-wrapper {
    padding: 0;
  }
  /* Generic */
  .vertical-heading h2 {
    font-size: 29px;
  }
  /* Services 02 */
  #services-tabs .tab-bg {
    padding: 16px 30px;
    min-height: 300px;
  }
  .service-tab p {
    line-height: 20px;
  }
  .about-item {
    padding: 55px 30px;
    margin-bottom: 35px;
  }
  .display-flex {
    display: flex !important;
  }
  .home-heading-img {
    height: 475px;
  }
  .padding-41 {
    padding: 41px 30px !important;
  }
  .community {
    width: 100%;
    height: 100%;
  }
  .community-2 {
    width: 220%;
    height: 100%;
  }
  .community-2 > img {
    height: 300px;
    width: 300px;
  }
}

/* Small Devices (Portrait Tablets & Small Desktops) */

@media (min-width: 768px) and (max-width: 991px) {
  /* Navigation */
  .site-nav-wrapper {
    padding: 0;
  }
  .home-heading-img {
    height: 475px;
  }
  /* Home */
  #home-heading h1 {
    font-size: 41px;
  }
  #home-text p {
    font-size: 13px;
  }
  /* Generic */
  .btn-general {
    padding: 8px 35px 8px 35px;
    font-size: 10px;
  }
  /* Generic */
  .vertical-heading h2 {
    font-size: 29px;
  }
  /* Services 02 */
  .r-tabs-accordion-title {
    text-align: center;
    margin-bottom: 10px;
  }
  .r-tabs-accordion-title a {
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    color: #212226;
    text-transform: uppercase;
    font-weight: 400;
    padding: 0;
    text-decoration: none;
  }
  /* Tab active state */
  .r-tabs-accordion-title.r-tabs-state-active a {
    color: #2eabd0;
    font-weight: 700;
    border-bottom: 3px solid #2eabd0;
    padding-bottom: 5px;
    -webkit-transition: border-color 300ms linear;
    transition: border-color 300ms linear;
  }
  /* Blog */
  .blog-post .post-meta p {
    line-height: 14px;
  }
  /* Contact */
  ul.office-details li {
    margin-right: 15px;
  }
  ul.office-details li i {
    width: 25px;
  }
  .about-item {
    padding: 55px 30px;
    margin-bottom: 35px;
  }
  .display-flex-event {
    display: flex;
  }
  .community {
    width: 100%;
    height: 100%;
  }
  .community-2 {
    width: 140%;
    height: 100%;
  }
}

/* Extra Small Devices (Landscape Phones & Portrait Tablets) */

@media (max-width: 767px) {
  /* Navigaiton */
  .navbar {
    padding: 20px 0;
  }
  .home-heading-img {
    height: 300px;
  }
  .home-heading-img > img {
    object-fit: none;
    width: 100%;
  }
  .site-nav-wrapper,
  .white-nav-top {
    padding: 0;
  }
  /* show mobile nav open btn */
  #mobile-nav-open-btn {
    display: block;
  }
  /* Home */
  #home-heading h1 {
    font-size: 22px;
  }
  #home-text p {
    font-size: 13px;
    line-height: 18px;
    margin: 8px 2px 30px 2px;
  }
  /* Generic */
  .btn-general {
    padding: 8px 25px 6px 25px;
    font-size: 10px;
  }
  /* About 01 */
  /* Large Content Box */
  .content-box-lg {
    padding: 60px 0 45px 0;
  }
  /* Vertical heading */
  .vertical-heading {
    text-align: center;
  }
  .vertical-heading h5 {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    display: block;
    position: static;
    margin-bottom: 15px;
  }
  .vertical-heading h2 {
    font-size: 29px;
    line-height: normal;
    margin: 0;
  }
  #about-left {
    margin-bottom: 50px;
  }
  #about-right {
    text-align: center;
  }
  /* About 02 */
  /* Medium Content Box */
  .content-box-md {
    padding: 40px 0;
  }
  .contact-box {
    padding: 20px 0;
  }
  .about-item {
    padding: 55px 30px;
    margin-bottom: 35px;
  }
  .about-item i {
    font-size: 30px;
  }
  .about-item h3 {
    font-size: 18px;
  }
  /* Team */
  #team-left .vertical-heading {
    min-height: 130px;
  }
  .skill h4 {
    font-size: 14px;
    line-height: 35px;
  }
  /* Services 01 */
  /* Horizontal heading */
  .horizontal-heading {
    margin-bottom: 40px;
  }
  .horizontal-heading h2 {
    font-size: 30px;
  }
  #services-01 img {
    margin: 35px 0;
  }
  .service,
  .service .icon {
    text-align: center;
  }
  .service h4 {
    font-size: 18px;
  }
  /* Services 02 */
  .r-tabs-accordion-title {
    text-align: center;
    margin-bottom: 10px;
  }
  .r-tabs-accordion-title a {
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    color: #212226;
    text-transform: uppercase;
    font-weight: 400;
    padding: 0;
    text-decoration: none;
  }
  /* Tab active state */
  .r-tabs-accordion-title.r-tabs-state-active a {
    color: #2eabd0;
    font-weight: 700;
    border-bottom: 3px solid #2eabd0;
    padding-bottom: 5px;
    -webkit-transition: border-color 300ms linear;
    transition: border-color 300ms linear;
  }
  #services-tabs .tab-bg {
    text-align: center;
    padding: 40px 30px;
  }
  #services-tabs h2 {
    font-size: 40px;
  }
  #services-tabs h3 {
    font-size: 28px;
  }
  /* Portfolio */
  #portfolio {
    padding-bottom: 0;
  }
  #isotope-filters button {
    padding-right: 15px;
  }
  /* Blog */
  #blog-left {
    text-align: center;
    margin-bottom: 30px;
  }
  .blog-post {
    margin-bottom: 25px;
  }
  /* Contact */
  #contact-left {
    text-align: center;
  }
  .office {
    margin-bottom: 40px;
  }
  ul.office-details li {
    display: block;
    margin-top: 25px;
  }
  ul.office-details li i {
    width: auto;
    display: block;
    margin-bottom: 3px;
  }
  ul.social-list {
    margin-bottom: 40px;
  }
}

/* Extra Small Devices (Portrait Phones & Smaller Devices) */

@media (max-width: 480px) {
  /* Home */
  #home-heading {
    height: 300px;
  }

  #home-heading h1 {
    font-size: 18px;
  }
  #home-text p {
    font-size: 11px;
    line-height: 16px;
    margin: 8px 10px 25px 10px;
  }
  /* Generic */
  .vertical-heading h2 {
    font-size: 22px;
  }
  /* Portfolio */
  #isotope-filters {
    text-align: center;
  }
  #isotope-filters button {
    font-size: 10px;
    padding-right: 14px;
  }
  .about-item {
    padding: 55px 30px;
    margin-bottom: 35px;
  }
  .community {
    width: 100%;
    height: 100%;
  }
  .community-2 {
    width: 100%;
    height: 100%;
  }
}
