body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* =========================================
    Default Theme
    ----------------------------------------
    Fonts: Raleway & Open Sans
    ----------------------------------------
    Colors:
        Moon Yellow:         #2eabd0
        Grey:                #212226
        White Smoke:         #f4f4f4
        White:               #fff
        Black:               #000   
============================================ */


/* =========================================
                Html, Body
============================================ */

html,
body {
    height: 100%;
}

body {
    color: #212226;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", sans-serif;
}

p {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
}

section {
    background: #fff;
    overflow: hidden;
}


/* =========================================
                Preloader
============================================ */

#preloader {
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

#status {
    background-image: url("assets/preloader.gif");
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -35px;
    margin-left: -35px;
}


/* =========================================
                Home
============================================ */

#home {
    background: none;
    height: 100%;
}

#home-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

#home-content {
    width: 100%;
    height: 100%;
    display: table;
}

#home-content-inner {
    display: table-cell;
    vertical-align: middle;
}

#home-heading {
    height: 400px;
}

#home-heading h1 {
    color: #fff;
    font-size: 65px;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
    display: inline-block;
}

#home-heading h1 span {
    color: #2eabd0;
    font-weight: 500;
}

#home-text p {
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    margin: 0 0 30px 0;
}

#home-heading-1 {
    padding-top: 150px;
}

#home-btn {
    margin-bottom: 20px;
}


/* =========================================
                Buttons
============================================ */

.btn-general {
    font-family: 'Raleway', sans-serif;
    border-radius: 28px;
    font-size: 13px;
    text-transform: uppercase;
    margin: 0 6px;
    padding: 12px 46px 12px 46px;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.btn-home {
    color: #fff;
    border: 1px solid #fff;
}

.btn-home:hover,
.btn-home:focus {
    color: #fff;
    background-color: #2eabd0;
    border: 1px solid #2eabd0;
}

.btn-connect {
    color: #000;
    border: 1px solid #000;
}

.btn-connect:hover,
.btn-connect:focus {
    color: #fff;
    background-color: #2eabd0;
    border: 1px solid #2eabd0;
}

.btn-yellow {
    color: #fff;
    border: 1px solid #2eabd0;
    background-color: #2eabd0;
}

.btn-yellow:hover,
.btn-yellow:focus {
    color: #fff;
    background-color: #212226;
    border: 1px solid #212226;
}

.btn-white {
    color: #212226;
    border: 1px solid #fff;
    background-color: #fff;
}

.btn-white:hover,
.btn-white:focus {
    color: #fff;
    background-color: #212226;
    border: 1px solid #212226;
}

.btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 3px 15px;
    border-radius: 4px;
    font-size: 22px;
}


/* =========================================
                Arrow Down
============================================ */

#arrow-down {
    position: absolute;
    left: 50%;
    bottom: 20px;
    color: #fff;
    font-size: 32px;
    width: 32px;
    height: 32px;
    text-align: center;
    margin-left: -16px;
    z-index: 1;
}

#arrow-down:hover,
#arrow-down:focus {
    color: #2eabd0;
}


/* =========================================
                Content Boxes
============================================ */

.content-box-lg {
    padding: 80px 0 60px 0;
}

.content-box-md {
    padding: 50px 0;
}

.content-box-sm {
    padding: 90px 0;
}

.contact-box {
    padding: 30px 0;
}


/* =========================================
        Vertical/Horizontal Headings
============================================ */

.vertical-heading h5 {
    color: #2eabd0;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    word-spacing: 9px;
    display: inline-block;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: relative;
    top: 60px;
    left: -53px;
}

.vertical-heading h2 {
    margin: 0 0 0 35px;
}

.horizontal-heading h2,
.vertical-heading h2 {
    font-size: 42px;
    font-weight: 100;
    line-height: 45px;
}

.horizontal-heading {
    margin-bottom: 40px;
}

.horizontal-heading h5 {
    font-size: 16px;
    word-spacing: 9px;
    color: #2eabd0;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 10px;
}

.horizontal-heading h2 {
    margin: 0;
}


/* =========================================
                About 01
============================================ */

#about-right p:first-child {
    margin-bottom: 30px;
    font-size: 14px;
}

#about-right ul {
   list-style: none;
   padding-left: 0;
}

#about-right ul li{
    font-size: 14px;
 }

#about-bottom {
    margin-top: 60px;
}

#about-bottom img {
    margin: 0 auto;
}


/* =========================================
                About 02
============================================ */

#about-02 {
    background-color: #f4f4f4;
    width: 100%;
}

.about-item {
    background-color: #fff;
    padding: 80px 30px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.about-item i {
    font-size: 42px;
    margin: 0;
}

.about-item h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.about-item hr {
    width: 45px;
    height: 3px;
    background-color: #2eabd0;
    margin: 0 auto;
    border: none;
}

.about-item p {
    margin-top: 20px;
}

.community {
    width: 350px;
    height: 350px;
}

.community-2 {
    width: 1110px; 
    height: 300px;
}

.community img {
    width: 50%;
    height: 50%;
    object-fit: contain;    
}

.community-2 img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}


/* Hover state */

.about-item:hover {
    background-color: #2eabd0;
}

.about-item:hover i,
.about-item:hover h3,
.about-item:hover p {
    color: #fff;
}

.about-item:hover hr {
    background-color: #fff;
}

.about-item:hover i {
    transform: translateY(-20px);
}


/* Smooth transition */

.about-item,
.about-item i,
.about-item hr {
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}
.about-item h3,
.about-item p {
   -webkit-transition: all 400ms linear;
    transition: all 400ms linear;  
}

/* =========================================
                Team
============================================ */

#team-left .vertical-heading {
    min-height: 160px;
}

.team-member {
    margin: 8px;
    position: relative;
}

.team-member-overlay {
    background-color: rgba(33, 34, 38, .9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.team-member-info {
    width: 100%;
    height: 100%;
    padding: 80px 0;
}

.team-member-info h6 {
    color: #2eabd0;
    font-size: 16px;
    margin: 0;
}

.team-member-info p {
    color: #fff;
}

.team-member:hover .team-member-overlay {
    opacity: 1;
}


/* =========================================
                Social Icons
============================================ */

ul.social-list {
    padding: 0;
    margin-top: 20px;
}

ul.social-list li {
    display: inline-block;
    padding: 0;
}

ul.social-list li a {
    border: 1px solid #fff;
    width: 35px;
    height: 35px;
    display: inline-block;
    line-height: 35px;
    color: #fff;
    border-radius: 50%;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

ul.social-list li:nth-child(1) a:hover {
    background: #3b5998;
    border-color: transparent;
}

ul.social-list li:nth-child(2) a:hover {
    background: #00aced;
    border-color: transparent;
}

ul.social-list li:nth-child(3) a:hover {
    background: #dd4b39;
    border-color: transparent;
}


/* =========================================
                Slider Buttons
============================================ */

.owl-theme .owl-nav [class*=owl-] {
    color: #212226;
    font-size: 40px;
    background: none;
    margin: 0;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: none;
    color: #212226;
}


/* =========================================
                Skills
============================================ */

.skill {
    margin-bottom: 20px;
}

.skill h4 {
    line-height: 62px;
    margin: 0;
    font-weight: normal;
}

.progress {
    height: 25px;
    border-radius: 0;
    background: #efefef;
    overflow: visible;
}

.progress-bar {
    background: #2eabd0;
    position: relative;
}

.progress-bar span {
    font-size: 11px;
    font-weight: 100;
    font-family: "Open Sans", sans-serif;
    background: #212226;
    padding: 3px;
    position: absolute;
    left: 97%;
    top: -47px;
}

.progress-bar span:before {
    content: "";
    border-top: 8px solid #212226;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
    bottom: -8px;
    left: 23%;
}


/* =========================================
                Services 01
============================================ */

#services-01 img {
    margin: 60px 0;
}

.service {
    margin: 35px 0;
}

.service .icon {
    font-size: 42px;
    margin: 0;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.service:hover .icon {
    color: #2eabd0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
}

.service h5 {
    font-size: 12px;
    text-transform: uppercase;
    color: #2eabd0;
    margin: 0 0 6px 0;
}

.service:hover h5 {
    color: #212226;
}

.service h4 {
    font-size: 24px;
    margin: 0 0 8px 0;
}

.service p {
    line-height: normal;
}

/* =========================================
                Portfolio
============================================ */

#portfolio {
    padding-bottom: 160px;
}

#portfolio .vertical-heading {
    margin-bottom: 50px;
}

.row.no-gutters [class*=col-] {
    padding: 0;
}

.portfolio-item {
    overflow: hidden;
    position: relative;
}


/* Filters CSS */

#isotope-filters {
    margin-bottom: 10px;
    padding: 20px 25px 20px 0;
}

#isotope-filters button {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-right: 35px;
    outline: 0;
}

#isotope-filters button span {
    display: block;
    padding-bottom: 5px;
    -webkit-transition: border-color .4s ease-in-out;
    transition: border-color .4s ease-in-out;
}

#isotope-filters button.active span {
    color: #2eabd0;
    border-bottom: 3px solid #2eabd0;
}


/* Zoom Effect */

.portfolio-item img {
    width: 100%;
    height: auto;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
}

.portfolio-item:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


/* Overlay Effect */

.portfolio-item-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(14, 14, 17, 0.9);
    cursor: -webkit-zoom-in;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}


/* Item Details Effect */

.portfolio-item-details {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.portfolio-item-details h3 {
    color: #2eabd0;
    text-transform: uppercase;
    font-size: 30px;
    margin: 0;
    padding: 0;
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.portfolio-item-details span {
    display: inline-block;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    height: 6px;
    width: 30px;
    margin: 11px auto 5px auto;
}

.portfolio-item-details p {
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}


/* Show Overlay on mouse over */

.portfolio-item:hover .portfolio-item-details h3 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.portfolio-item:hover .portfolio-item-details p {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.portfolio-item:hover .portfolio-item-overlay {
    opacity: 1;
}

/* =========================================
                Blog
============================================ */

#blog {
    background: #f4f4f4;
}

#blog-left p {
    margin: 25px 0;
}

#blog-btn a {
    margin: 0;
}

.blog-post {
    background: #fff;
    padding: 20px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.blog-post h4 {
    line-height: 40px;
    margin: 0;
}

.blog-post>p {
    line-height: 24px;
    margin: 0;
    font-weight: 300;
}

.blog-post a {
    display: inline-block;
    margin-top: 25px;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    color: #2eabd0;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    -webkit-transition: all 400ms linear;
    transition: all 400ms linear;
}

.blog-post a:hover {
    color: #212226;
}

.blog-post .post-meta {
    border-top: 1px solid #e0e0e0;
    margin-top: 30px;
    padding-top: 10px;
}

.blog-post .post-meta p {
    font-size: 11px;
    font-family: "Raleway", sans-serif;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
}


/* =========================================
                Contact
============================================ */


/* Contact Left */

#contact-left p {
    margin: 25px 0 20px 0;
}

ul.office-details {
    list-style: none;
    padding: 0;
}

ul.office-details li {
    font-family: "Open Sans", sans-serif;
    margin-top: 12px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

ul.office-details li i {
    font-size: 20px;
    width: 45px;
}

#contact-left ul.social-list li a {
    color: #212226;
    border: 1px solid #212226;
    text-align: center;
}

#contact-left ul.social-list li a:hover {
    color: #fff;
    border-color: transparent;
}


/* Contact Right */

#contact-right {
    padding: 25px 30px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

#contact-right h4 {
    font-size: 24px;
}

#contact-right p {
    margin-bottom: 25px;
}

#contact-right form .form-control {
    font-family: "Open Sans", sans-serif;
    padding: 10px 15px;
    font-size: 12px;
    line-height: 24px;
}

#contact-right form textarea.form-control {
    min-height: 164px;
}


/* =========================================
                Footer
============================================ */

footer {
    background: #212226;
    padding: 30px;
}

footer p {
    color: #fff;
    line-height: 20px;
    margin: 0;
}

footer p span {
    color: #2eabd0;
}


/* =========================================
                Navigation
============================================ */

.navbar {
    padding: 35px 0 20px 0;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
}

.site-nav-wrapper {
    padding: 0 85px;
}

.navbar-brand {
    margin-top: -15px;
}

ul.navbar-nav>li>a {
    font-family: "Raleway", sans-serif;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

ul.navbar-nav>li>a:hover,
ul.navbar-nav>li>a:focus {
    background: none;
    color: #2eabd0;
}


/* White Navigation */

.white-nav-top {
    background: #fff;
    padding: 10px 0;
    -webkit-box-shadow: 0 8px 6px -9px #999;
    box-shadow: 0 8px 6px -9px #999;
    z-index: 2;
}

.white-nav-top ul.navbar-nav>li>a {
    color: #212226;
}

.white-nav-top ul.navbar-nav>li>a:hover,
.white-nav-top ul.navbar-nav>li>a:focus {
    color: #2eabd0;
}


/* Scroll spy active state */

.white-nav-top ul.navbar-nav>li.active>a {
    color: #2eabd0;
    font-weight: 500;
}


/* =========================================
                Animation
============================================ */

#home-heading-1,
#home-heading-2,
#home-text,
#home-btn,
#arrow-down i {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
}

#home-heading-1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

#home-heading-2 {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

#home-text {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

#home-btn {
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

#arrow-down i {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}
